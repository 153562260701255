export default class FormContact{
    static initFromDom(){

        $body.on("input","form[data-form-contact] [data-is-mandatory='error']",function(e){
            if($(this).val()){
                $(this).attr("data-is-mandatory","");
            }
        });

        $body.on("submit","form[data-form-contact]",function(e){
            e.preventDefault();
            let $form=$(this);
            let $erreur=$(this).find(".error");
            let $success=$(this).find(".success");
            let email=$form.find("[name='email']").val();
            let message=$form.find("[name='message']").val();
            let subject=$form.find("[name='subject']").val();

            $erreur.addClass("hidden");
            $success.addClass("hidden");
            $erreur.text("");
            $success.text("");

            //teste les champs obligatoires
            let $mandatory=$form.find("[data-is-mandatory]");
            let mandatoryError=false;
            $mandatory.each(function(){
                if(!$(this).val()){
                    mandatoryError=true;
                    $(this).attr("data-is-mandatory","error");
                }
            });

            if(mandatoryError){
                $erreur.removeClass("hidden");
                $erreur.text("Veuillez saisir tous les champs obligatoires.");
            }else{
                $success.removeClass("hidden");
                $success.text("Veuillez patienter...");
                PovApi.action("formContact",
                    {
                        email:email,
                        message:message,
                        subject:subject
                    }
                ).then(
                    function(e){
                        if(e.success){
                            $success.removeClass("hidden");
                            $success.text("Merci, le lien vous a bien été envoyé.");
                            let $form = $("form[data-form-contact]");
                            $form.find("[name='email']").val('');

                        }else{
                            $success.addClass("hidden");
                            $erreur.removeClass("hidden");
                            $erreur.text(e.errors.join(". "));
                        }
                    }
                ).catch(
                    function(b){
                        console.error("b",b);
                    }
                );
            }


        })
    }
}